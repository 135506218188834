import React, { useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"
import { motion, Motion } from "framer-motion"
import Seo from "../components/seo"
import styled from "styled-components"
import Grid from "../components/imageGrid/grid"
import getYouTubeId from "get-youtube-id"
import YouTube from "react-youtube"
import PortableText from "@sanity/block-content-to-react"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination, Scrollbar, A11y } from "swiper"
// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-cards"
import "swiper/css/navigation"
import "swiper/css/pagination"
// import required modules
import { EffectCards } from "swiper"
//locomotive scroll
//loco

import Image from "../components/imageGrid/image"
import { StaggerTextReveal } from "stagger-text-reveal-animation"

export default function ClientPageSingle({ data: { client } }) {
  //styles
  const SinglePage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    z-index: 2;
  `
  const ContentWrap = styled.div`
    position: absolute;

    padding: 0 0 0 0;
    z-index: 1000;
    grid-column-start: 2;
    grid-column-end: 24;
    @media (min-width: 768px) {
      grid-column-start: 6;
      grid-column-end: 21;
    }
    @media (min-width: 1200px) {
      grid-column-start: 7;
      grid-column-end: 19;
    }
    @media (min-width: 1440px) {
      grid-column-start: 7;
      grid-column-end: 19;
    }
    position: relative;
    margin-top: 30px;
    padding-top: 150px;
    @media (min-width: 768px) {
      padding-top: 70px;
    }
    padding-bottom: 120px;
    font-family: "PPneueregular";
    color: var(--white);
  `

  const ImageWrap = styled.div``

  const Title = styled.div`
    font-family: "PPneueregular";
    color: var(--white);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 30px 0;
    @media (min-width: 768px) {
      margin: 70px 0;
    }
    @media (min-width: 1100px) {
      margin: 40px 0;
    }
    text-align: center;
    h1 {
      font-family: "PPneueregular";
      font-size: 40px;
      margin: 0;
      @media (min-width: 768px) {
        font-size: 40px;
        line-height: 1.2;
      }
      @media (min-width: 1200px) {
        font-size: 50px;
        line-height: 1.2;
      }
      @media (min-width: 1800px) {
        font-size: 50px;
        line-height: 1.2;
      }
    }
    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
    }
    .bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
    }
  `

  const Description = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 70px 0;
  `

  const VideoWrap = styled.div`
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin: 70px 0;
    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  `
  let imageRepeater = ""
  if (client.allImages) {
    imageRepeater = client.allImages.images.map((image, i) => (
      <SwiperSlide>
        <ImageWrap>
          <Image image={image.asset.gatsbyImageData} />
        </ImageWrap>
      </SwiperSlide>
    ))
  } else {
    imageRepeater = ""
  }

  const text = client.name
  const words = text.split(" ")
  //font size for media query
  let fs = 24
  let part1 = " "
  let part2 = " "
  words.forEach((word, idx) => {
    if (idx < 3) {
      part1 += " " + word
    } else {
      part2 += " " + word
    }
  })

  //video

  //videopts
  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  }

  let videolink = ""
  if (client.video) {
    const videourl = client.video
    const id = getYouTubeId(client.video)
    videolink = (
      <VideoWrap
        as={motion.div}
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.25,
          delay: 0.35,
          ease: [0.43, 0.13, 0.23, 0.96],
        }}
      >
        <iframe
          mute="1"
          autoplay="1"
          src={"https://www.youtube.com/embed/" + id}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </VideoWrap>
    )
  } else {
    videolink = ""
  }

  return (
    <SinglePage>
      <div className="grid24">
        <ContentWrap>
          <Title
            as={motion.div}
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.25,
              delay: 0.25,
              ease: [0.43, 0.13, 0.23, 0.96],
            }}
          >
            <h1>{client.name}</h1>
          </Title>
          {videolink}
          <Swiper
            as={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.35,
              delay: 0.45,
              ease: [0.43, 0.13, 0.23, 0.96],
            }}
            modules={[Navigation, Pagination, Scrollbar, A11y, EffectCards]}
            effect={"cards"}
            grabCursor={true}
            className="mySwiper"
            navigation
            slideShadows={false}
            pagination={true}
          >
            {imageRepeater}
          </Swiper>
          <Description
            as={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.25,
              delay: 0.45,
              ease: [0.43, 0.13, 0.23, 0.96],
            }}
          >
            <p>{client.description}</p>
          </Description>
        </ContentWrap>
      </div>
    </SinglePage>
  )
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query ($slug: String!) {
    client: sanityClient(slug: { current: { eq: $slug } }) {
      name
      description
      year
      video
      slug {
        current
      }
      image {
        asset {
          gatsbyImageData(
            placeholder: NONE
            layout: FULL_WIDTH
            width: 1400
            breakpoints: [750, 1080, 1366, 1920]
          )
        }
      }
      allImages {
        _rawImages(resolveReferences: { maxDepth: 40 })
        images {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`
